<template>
  <div>
    <s-dialog
      :visible="visible"
      :show-close="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      class="resume-shipment-dialog"
      @update:visible="updateVisible"
      @close-from-icon="triggerNotice('close')"
      @close="onClosed"
      @open="onOpen"
    >
      <div 
        v-if="info && info.msg"
        class="resume-shipment-text"
        v-html="info.msg"
      >
      </div>
      <div class="resume-shipment-text">
        {{ resumeShipmentText }}
      </div>
      <s-loading
        v-if="showLoading"
        type="curpage"
        :show="showLoading"
      />
      <template #footer>
        <div class="resume-shipment-dialog__footer">
          <s-button
            :type="['primary', 'H44PX']"
            @click="onConfirm"
          >
            {{ language.SHEIN_KEY_PWA_34358 }}
          </s-button>
          <s-button
            :type="['H44PX']"
            @click="onCancel"
          >
            {{ language.SHEIN_KEY_PWA_34361 }}
          </s-button>
        </div>
      </template>
    </s-dialog>

    <s-drawer
      v-model:visible="showBatchOrderDialog"
      type="normal"
      close-on-click-modal
      append-to-body
      @close="onBatchClosed"
    >
      <template #title>
        <p class="shipment-detail__title">
          {{ language.SHEIN_KEY_PWA_35787 }}
        </p>
      </template>

      <div class="shipment-detail__content">
        <div class="shipment-detail__content-title">
          {{ template(totalSuccess, language.SHEIN_KEY_PWA_35788) }}
        </div>
        <div class="shipment-detail__order-list">
          <div
            v-for="order in batchOrderList"
            :key="order.billno"
          >
            <div>{{ language.SHEIN_KEY_PWA_16822 }} {{ order.billno }}</div>
            <div class="shipment-detail__addTime">
              {{ handlerDate(order.add_time) }}
            </div>
            <div class="shipment-detail__goods-info">
              <div
                v-for="goods in getAggregateGoods(order.order_goods_list)"
                :key="goods.id_ots"
                class="shipment-detail__order-item"
              >
                <div
                  class="shipment-detail__order-item"
                >
                  <CropImageContainer
                    :img-src="transformImg({ img: goods.product.goods_thumb })"
                    fixed-ratio="3-4"
                  />
                  <div class="cell-desp">
                    <span
                      class="cell-count"
                    >x{{ goods.quantity }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="shipment-detail__footer">
        <s-button
          width="100%"
          class="shipment-detail__title"
          :type="['H80PX','primary']"
          @click="closeBatchOrderDialog"
        >
          {{ language.SHEIN_KEY_PWA_24561 }}
        </s-button>
      </div>
    </s-drawer>
  </div>
</template>

<script>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { defineComponent } from 'vue'
import { mapMutations } from 'vuex'
import schttp from 'public/src/services/schttp'
import { transformImg, isFunction, template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import orderLogic from 'public/src/pages/common/orderLogic'

daEventCenter.addSubscriber({ modulecode: '1-20-1' })

export default defineComponent({
  name: 'ResumeShipmentDialog',
  components: {
    SLoading,
    SButton,
    SDialog,
    SDrawer,
    CropImageContainer
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => {}
    },
    orderInfo: {
      type: Object,
      default: () => {}
    },
    info: {
      type: Object,
      default: () => {}
    },
    asyncCallbacks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isSuccess: false,
      showLoading: false,
      batchOrderList: [],
      showBatchOrderDialog: false,
      totalSuccess: 0
    }
  },
  computed: {
    resumeShipmentText() {
      const textMap = {
        'order_detail': this.language.SHEIN_KEY_PWA_35786,
        'order_list': this.language.SHEIN_KEY_PWA_35786
      }

      return textMap[this.info?.scene] || this.language.SHEIN_KEY_PWA_34951
    }
  },
  methods: {
    transformImg,
    template,
    ...mapMutations(['changeRootStatus']),
    updateVisible(event) {
      this.$emit('update:visible', event)
    },
    changeLoading(loadingStatus = false) {
      this.showLoading = loadingStatus
    },
    triggerNotice(operation = '') {
      daEventCenter.triggerNotice({
        daId: '1-20-1-181',
        extraData: {
          order_id: this.orderInfo?.billno || '',
          scene: this.info?.scene || '',
          operation,
        },
      })
    },
    async onConfirm() {
      this.triggerNotice('resume')
      this.changeLoading(true)
      const {
        billno = '',
        order_type = '',
        order_mark_list = []
      } = this.orderInfo || {}
      const markItem = (order_mark_list || []).find(i => i.mark_type == 1 && i.mark_type_status == 1)

      const res = await schttp({
        method: 'POST',
        url: '/api/orders/base/orderMark/update',
        data: {
          billNo: billno,
          firstMarkType: 1, // 一级类别
          secondMarkType: markItem ? markItem.trouble_reason : '', // 二级类别
          orderType: order_type || 1,
          type: 2, // 操作类型：1 为标记 2为取消
        }
      }).catch(() => {
        this.changeLoading(false)
      })
      this.changeLoading(false)
      if (res?.code == 0) {
        this.isSuccess = true
        const successList = res?.info?.successList || []
        if (successList.length == 1){
          setTimeout(() => {
            SToast(this.language.SHEIN_KEY_PWA_34362)
          }, 500)
        } else if (successList.length > 1) {
          if (this.info?.scene !== 'outofstock') {
          // successList.length > 1判断是否存在批量取消场景
            this.totalSuccess = successList.length
            await schttp({
              method: 'POST',
              url: '/api/orders/base/batchQueryOrderInfoInCommentScene/query',
              data: {
                billnos: successList
              }
            })
              .then(res => {
                if (res?.code == 0) {
                  this.batchOrderList = res?.info || []
                  this.showBatchOrderDialog = true
                } else {
                  SToast(this.language.SHEIN_KEY_PWA_34362)
                }
              })
              .catch(() => {
                SToast(this.language.SHEIN_KEY_PWA_34362)
              })
          } else {
            SToast(this.language.SHEIN_KEY_PWA_34362)
          }
        }

        this.updateVisible(false)
      } else {
        SToast(this.language.SHEIN_KEY_PWA_34360)
      }
    },
    onClosed() {
      if (this.batchOrderList.length) {
        return
      }
      if (Array.isArray(this.asyncCallbacks) && this.asyncCallbacks.length > 0) {
        this.asyncCallbacks.forEach(cb => {
          if (isFunction(cb)) {
            cb(this.isSuccess)
          }
        })
      }
      this.isSuccess = false
    },
    onBatchClosed() {
      if (Array.isArray(this.asyncCallbacks) && this.asyncCallbacks.length > 0) {
        this.asyncCallbacks.forEach(cb => {
          if (isFunction(cb)) {
            cb(this.isSuccess)
          }
        })
      }
      this.isSuccess = false
    },
    onOpen(){
      daEventCenter.triggerNotice({
        daId: '1-20-1-180',
        extraData: {
          order_id: this.orderInfo?.billno || '',
          scene: this.info?.scene || ''
        },
      })
    },
    onCancel() {
      this.triggerNotice('pause')
      this.updateVisible(false)
      this.$emit('cancel')
    },
    handlerDate(date) {
      if (!date) return ''
      return orderLogic.orderDateFormat(date, true)
    },
    closeBatchOrderDialog() {
      this.showBatchOrderDialog = false
    },
    getAggregateGoods(goodsList) {
      const aggregatedItems = {}
      goodsList.forEach((item) => {
        const goodsId = item.product.goods_id
        if (!aggregatedItems[goodsId]) {
          aggregatedItems[goodsId] = { ...item, quantity: 0 }
        }
        aggregatedItems[goodsId].quantity += parseInt(item.quantity, 10)
      })

      return Object.values(aggregatedItems)
    },
  }
})
</script>
<style lang="less">
.resume-shipment-dialog {
  .resume-shipment-text {
    color: @sui_color_gray_dark1;
    font-size: 28/75rem;
    font-weight: 400;
    line-height: normal;
    text-align: left;

    & + .resume-shipment-text {
      margin-top: 40/75rem
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24/75rem;

    button {
      width: 100%;
    }
  }
}
</style>
<style lang="less" scoped>
.shipment-detail {
  background: @sui_color_white;

  &__content-title {
    padding: 24/75rem 24/75rem 0 24/75rem;
    line-height: normal;
    color: @sui_color_gray_dark2;
    &::after {
      content: "";
      display: block;
      width: 100%;
      border-bottom: 1px solid @sui_color_gray_weak1;
      margin-top: 24/75rem;
    }
  }
  &__order-list {
    padding: 24/75rem;
    overflow: hidden;
  }
  &__goods-info {
    display: flex;
    gap: 16/75rem;
    overflow-x: auto;
    overflow-y: hidden;
    margin-right: -24/75rem;
    padding: 16/75rem 0 24/75rem;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__order-item {
    display: inline-block;
    position: relative;
    width: 148/75rem;
    height: 196/75rem;
    img {
      width: 100%;
    }
  }

  &__addTime {
    .font-dpr(20px);
    color: #666;
  }

  &__footer {
    padding: 0 24/75rem 24/75rem 24/75rem;
  }
}
.cell-desp {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0.5733rem;
  height: 0.3733rem;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  .cell-count {
    color: #666;
    .font-dpr(20px);
  }
}
</style>
